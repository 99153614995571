html,
body,
#root {
	padding: 0;
	margin: 0;
	width: 100%;
	height: 100%;
	min-height: -webkit-fill-available;
}

#root {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #e1e3e7;
}

@font-face {
	font-family: 'Pretendard-Regular';
	src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
		format('woff');
	font-weight: 400;
	font-style: normal;
}
